<template>
  <div class="schedule-element text-left">
    <div class="time font-ultra text-red-600">
      {{fromAr[0]}}<sup>{{fromAr[1]}}</sup> — {{toAr[0]}}<sup>{{toAr[1]}}</sup>
    </div>
    <div class="">
      <div v-html="text" class="text-4xl font-ultra"></div>
      <gradient-button v-if="showMore" text="Подробнее" @click="$emit('click')"/>
    </div>
  </div>
</template>

<script>
import GradientButton from "@/components/GradientButton";
export default {
  name: "ScheduleElement",
  components: {GradientButton},
  props: ["from", "to", "text", "showMore"],
  computed: {
    fromAr: function (){
      return this.from.split(':')
    },
    toAr: function (){
      return this.to.split(':')
    }
  }
}
</script>

<style lang="scss">
.schedule-element {
  @apply  py-2 md:py-0;


  .time {
    @apply  text-4xl;
    line-height: 1.8rem;
    @media (max-width: 767px) {
      line-height: 1.4rem;
    }
  }
}
</style>

