<template>
  <footer>
    <section class="footer-wrapper max-w-6xl">
      <contact-screen class="col-span-3 mx-auto max-w-2xl" />
      <div class="col-span-2 lg:col-span-1 md:col-span-1 text-left">
        <span class="title">Техническая поддержка:</span>
        <div class="flex flex-wrap flex-col mt-2">
          <p>Тел.: +7 985 634 67 75</p>
          <p>(What’s App и Telegram)</p>
        </div>
      </div>
      <div class="socials col-span-2 lg:col-span-1 md:col-span-2 text-left">
        <span class="title">Связаться с нами:</span>
        <div class="flex flex-wrap flex-col gap-2">
          <p class="mt-1"><a href="mailto:productcult@mts.ru">productcult@mts.ru</a></p>
          <p class="mt-1"><a href="mailto:aleksandr.salikov@mts.ru">aleksandr.salikov@mts.ru</a></p>
          <p class="mt-1"><a href="mailto:impetsh@mts.ru">impetsh@mts.ru</a></p>
          <p class="mt-2">Telegram:</p>
          <p class="mt-1"><a href="https://t.me/sashasalikov" target="_blank">@sashasalikov</a></p>
          <p class="mt-1"><a href="https://t.me/FoodPoliceBot1" target="_blank">@FoodPoliceBot1</a></p>
        </div>
      </div>
      <div class="socials col-span-2 lg:col-span-1">
        <div class="flex space-x-5 items-center mb-4">
          <img src="../assets/images/technopark-logo.svg" class="h-10" />
          <p class="text-sm">Партнер<br>Agile Day 2021</p>
        </div>

<!--        <p class="title text-left mt-2">Социальные сети</p>
        <div class="flex">
          <a class="socials__item" target="_blank" href="https://ru-ru.facebook.com/mts/">
            <svg class="icon-fb" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.6 20v-9.12h3l.4-3.75H6.6V5.25c0-.98.1-1.5 1.5-1.5h1.88V0h-3C3.37 0 2.1 1.82 2.1 4.88v2.25H0v3.75h2.1V20h4.5z"></path>
            </svg>
          </a>
          <a class="socials__item" target="_blank" href="https://vk.com/mts">
            <svg class="icon-vk" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.08 12.95s.4-.05.6-.28c.19-.2.18-.6.18-.6s-.03-1.82.8-2.1c.8-.26 1.84 1.78 2.94 2.56.83.59 1.46.46 1.46.46l2.94-.04s1.53-.1.8-1.35c-.06-.1-.42-.93-2.18-2.62-1.84-1.77-1.6-1.49.62-4.55 1.35-1.87 1.9-3 1.73-3.5C21.8.48 20.8.6 20.8.6l-3.3.02s-.25-.04-.43.07a.96.96 0 00-.3.37s-.52 1.45-1.22 2.67c-1.47 2.6-2.06 2.73-2.3 2.57-.56-.37-.42-1.5-.42-2.3 0-2.52.37-3.57-.72-3.84A5.46 5.46 0 0010.58 0C9.4 0 8.4 0 7.83.3c-.37.18-.66.61-.49.63.22.03.72.14.98.51.34.48.33 1.55.33 1.55s.2 2.96-.46 3.33c-.44.25-1.05-.27-2.37-2.62-.67-1.2-1.18-2.53-1.18-2.53s-.1-.25-.27-.38C4.16.62 3.86.57 3.86.57L.72.6S.24.6.07.82c-.15.19-.01.58-.01.58s2.46 5.97 5.25 8.97c2.55 2.76 5.45 2.58 5.45 2.58h1.32z"></path>
            </svg>
          </a>
          <a class="socials__item" target="_blank" href="https://ru.linkedin.com/company/mts-group">
            <svg class="icon-in" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3.41 16H.26V4.86h3.15V16zM1.84 3.57C.82 3.57 0 2.77 0 1.8 0 .8.82 0 1.84 0c1.01 0 1.83.8 1.83 1.79 0 .98-.82 1.78-1.83 1.78zM16 16h-3.15v-5.68c0-3.4-4.2-3.15-4.2 0V16H5.52V4.86h3.15v1.79C10.12 4.03 16 3.83 16 9.15V16z"></path>
            </svg>
          </a>
          <a class="socials__item" target="_blank" href="https://zen.yandex.ru/mts_media">
            <svg class="icon-zen" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M24 12.13v-.26c-5.31-.16-7.74-.3-9.68-2.19-1.9-1.94-2.03-4.37-2.19-9.68h-.26c-.16 5.31-.3 7.74-2.19 9.68-1.94 1.9-4.37 2.03-9.68 2.19v.26c5.31.16 7.74.3 9.68 2.19 1.9 1.94 2.03 4.37 2.19 9.68h.26c.16-5.31.3-7.74 2.19-9.68 1.94-1.9 4.37-2.03 9.68-2.19z"></path>
            </svg>
          </a>
        </div>-->
      </div>
      <div class="col-span-3 text-left">
        © 2021 ПАО «МТС». Все права защищены.
      </div>
    </section>
  </footer>
</template>

<script>

import ContactScreen from "@/components/ContactScreen";
export default {
  name: "FooterBlock",
  components: {ContactScreen},
}
</script>

<style lang="scss">

footer {
  @apply text-white bg-black;
  .footer-wrapper{
    @apply grid grid-cols-3 w-full px-4 md:px-4 py-10 md:gap-x-20 md:gap-y-10 gap-y-5 mx-auto;
  }

  a {
    @apply transition-colors hover:text-red-600;
  }

  .socials {
  }

  .title {
    @apply font-medium;
  }

  .socials__item {
    @apply flex m-2 w-10 h-10 rounded-full transition-colors items-center justify-center;
    background: #1c1c1e;

    &:first-child {
      @apply ml-0;
    }

    .icon-fb {
      width: 10px;
      height: 20px;
    }

    .icon-vk {
      width: 22px;
      height: 13px;
    }

    .icon-in {
      width: 16px;
      height: 16px;
    }

    .icon-zen {
      width: 24px;
      height: 24px;
    }

    path {
      @apply transition-colors;
      fill: #fff;
    }

    &:hover {
      @apply bg-white;

      path {
        fill: #000;
      }
    }
  }
}
</style>
