<template>
    <div class="contact text-center">
      <h2 class="text-xl text-white font-black">Материалы с мероприятия</h2>
      <input type="email" id="email"
             v-model="email"
             class="rounded-3xl px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full text-center"
             placeholder="your@email.com"/>
      <button @click="sendEmail" class="mx-auto">Получить</button>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "ContactScreen",
  data() {
    return {
      email: null,
      enable: true
    }
  },
  methods: {
    sendEmail() {
      if(!this.enable) return;
      if(!this.validateEmail(this.email)) {
        alert('Проверьте правильность email')
        return
      }

      axios.post('https://api.papa-carlo.ru/app/api/bot64de5678-476a-4e39-8a06-11f5eb7414b4/run', {email: this.email}).then( resp => {
        this.email = ''
        this.enable = false
        setTimeout(() => { this.enable = true }, 60000)
        alert(resp.data.data.message)
      })
    },
    validateEmail(email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

  }
}
</script>

<style lang="scss">
.contact {
  @apply mx-auto rounded-3xl px-16 py-10;
  background-color: #1a1a1c;

  input {
    @apply font-medium uppercase text-black;
    letter-spacing: 1px;
  }

  button {
    @apply uppercase text-red-600 font-medium text-xl px-5 py-2 mt-5
    transition-colors focus:outline-none border-2 border-red-600 rounded-3xl
    hover:bg-red-500 hover:text-white;

    letter-spacing: 1px;
  }
}

</style>
