<template>
  <div class="relative text-center">
    <div class="rounded-full h-40 w-40 overflow-hidden mx-auto bg-cover bg-center" :class="{'grayscale' : !colored}"
         :style="{ backgroundImage: 'url(' + image + ')'}"
    >
    </div>
    <p class="font-black text-white mt-2">{{ name }}</p>
    <p class="text-white text-sm" v-html="subName"></p>
    <gradient-button text="Подробнее" small v-if="moreInfo" @click="$emit('moreInfo')"/>
  </div>
</template>

<script>
import GradientButton from "@/components/GradientButton";
export default {
  name: "SpeakerCard",
  components: {GradientButton},
  props: {
    image: {
      type: String,
      default: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/face-wash-2-1561136582.jpg?resize=980:*'
    },
    name: {
      default: 'Александра Григорьева'
    },
    subName: {
      default: 'Директор маркетингового отдела'
    },
    moreInfo: {
      type: String,
      default: undefined
    },
    colored: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      gradient: 'linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73))'
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  .grayscale {
    filter: grayscale(1);
  }

  background: #eaebfb;
  background-position: center center;
  background-size: cover;
  height: 400px;
}
</style>
