<template>
  <modal :name="name" adaptive height="auto" width="90%" :max-width="600">
    <div v-html="html"></div>
    <gradient-button @click="close" text="ЗАКРЫТЬ"></gradient-button>
  </modal>
</template>

<script>
import GradientButton from "@/components/GradientButton";
export default {
  name: "ScheduleModal",
  components: { GradientButton },
  props: ['html', 'name'],
  methods: {
    close() {
      this.$modal.hide(this.name);
    }
  }
}
</script>

<style lang="scss">
.vm--overlay {
  background: whitesmoke !important;
  opacity: 0.9 !important;
}

.vm--modal {
  @apply pt-5 pb-7 px-10 border-gray-200 border-2 shadow-2xl
  text-left text-gray-500 relative;
  border-radius: 0.75rem !important;

  b {
    @apply block mt-2;
    color: #25174d;
  }

  ul {
    @apply list-disc
  }

  button {
    color: #25174d;
    @apply mt-2 w-full text-left font-bold focus:outline-none hover:opacity-70 transition-opacity;
  }
}

</style>
