<template>
  <section class="schedule-screen relative">
    <schedule-modal :html="selectedTime.modal" name="schedule-modal"/>
    <h1 class="dark-blue">ПРОГРАММА</h1>
<!--    <div class="bottom-outline-header text-empty__black font-ultra top-0 md:-top-4 lg:-top-14">ПРОГРАММА</div>-->
    <div class="schedule z-10 relative">
      <schedule-element v-for="(time, key) in times"
                        class="col-span-3 md:col-span-1 md:pl-0 mt-10 md:mt-0"
                        :key="key"
                        :from="time.from"
                        :to="time.to"
                        :text="time.text"
                        :show-more="!!time.modal"
                        @click="showModal(time)"
      />
    </div>
  </section>
</template>

<script>
import ScheduleElement from "./NewScheduleElement";
import ScheduleModal from "./ScheduleModal";

export default {
  name: "ScheduleScreen",
  components: {ScheduleModal, ScheduleElement},
  data() {
    return {
      times: [
        {
          from: '10:00',
          to: '12:00',
          text: 'Воркшопы',
          modal: '<b>10.00 – 10.40</b>' +
              '<b>Окно №1.</b> Светлое кибербудущее по версии Моториканцев. <br> Василий Хлебников, сооснователь Моторики<br>' +
              '<b>Окно №2.</b> Самокаты и города: от борьбы к партнёрству. <br>Дмитрий Замалеев, Senior data analyst, Bolt <br><br>'+
              '<b>10.50 – 11.30</b>' +
              '<b>Окно №1.</b> Личный опыт строительства и персонализации экосистем для премиум сегмента. <br> Иван Кочетов, основатель TheWAAY '      +
          '<b>Окно №2.</b> Как краудсорсинг упрощает жизнь и экономит миллионы рублей компаниям. <br> Владимир Болдырев, Менеджер по развитию бизнеса в Яндекс.Толока<br>'
        },
        {
          from: '12:00',
          to: '14:30',
          text: 'Баттлы',
          modal: '<b class="block mb-2">Номинация #мтс_моя_экосистема</b>' +
              'МТС Cashback<br>' +
              'Data Science Experience<br>' +
              'МТС Твой бизнес<br>' +
              'МТС Premium<br>' +
              'Экосистемные модули<br>'+
              '<b class="block my-2">Номинация #сделал_круто</b>' +
              'МТС Live <br>' +
              'МТС Маркетолог <br>' +
              'Необанк<br>' +
              'Сегмент Музыка <br>'
        },
        {
          from: '14:30',
          to: '15:00',
          text: 'Награждение',
        },


      ],
      selectedTime: {}
    }
  },
  methods: {
    showModal(time) {
      this.selectedTime = time
      if (this.selectedTime.modal)
        this.$modal.show('schedule-modal');
    }
  }
}
</script>

<style lang="scss">
.schedule-screen {
  .schedule {
    @apply grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 lg:grid-flow-col gap-x-0 gap-y-3 md:gap-x-10 lg:gap-y-20 md:gap-y-10 py-5 md:py-10;
  }

  h1 {
    //@apply px-3 md:px-0;
  }
  @apply relative mx-auto;
}
</style>
