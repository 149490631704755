<template>
  <section  class="relative speakers">
    <schedule-modal :html="html" name="speakers-modal"/>

    <!--    <div class="bottom-outline-header text-empty__white font-ultra lg:-top-20 -top-16">{{ smartHeader }}</div>-->
    <div class="flex justify-between relative" :class="{'items-end' : subline, 'items-center' : !subline}">
      <div>
        <h1 v-html="header"></h1>
        <h2 v-html="subline" class="mt-5"></h2>
      </div>
      <div class="flex">
        <button class="prev" @click="navigation('backward')">→</button>
        <button @click="navigation('forward')">→</button>
      </div>
    </div>
    <carousel
        ref="carousel"
              class="mt-4"
              :paginationEnabled="true"
              :autoplayHoverPause="false"
              :paginationSize="8"
              :paginationPadding="4"
              paginationActiveColor="#ff0000"
              loop
              :autoplayTimeout="4000"
              :perPageCustom="[[300, 1], [480, 2], [1024, 3], [1280, 4]]">
      <slide v-for="(speaker, i) in speakers" :key="i">
        <speaker-card :name="speaker.name"
                      :sub-name="speaker.subName"
                      :image="speaker.image"
                      :more-info="speaker.moreInfo"
                      @moreInfo="selectItem(speaker)"
                      colored/>
      </slide>
    </carousel>
  </section>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import SpeakerCard from "./SpeakerCard";
import ScheduleModal from "@/components/Schedule/ScheduleModal";

export default {
  name: "SpeakersScreen",
  props: {
    header: {
      default: 'СПИКЕРЫ'
    },
    smartHeader: {
      default: null
    },
    subline: {},
    speakers: {
      default: () => {
        return [
          {
            image: require('../../assets/speakers/sp-1.jpeg'),
            name: 'Владимир Болдырев',
            subName: 'Менеджер по развитию бизнеса в Яндекс.Толока'
          },
          {
            image: require('../../assets/speakers/sp-2.jpeg'),
            name: 'Дмитрий Замалеев',
            subName: 'Senior data analyst, Bolt'
          },
          {
            image: require('../../assets/speakers/sp-3.jpeg'),
            name: 'Василий Хлебников',
            subName: 'Сооснователь Моторики'
          },
          {
            image: require('../../assets/speakers/sp-4.jpeg'),
            name: 'Иван Кочетов',
            subName: 'Основатель TheWAAY'
          },
        ]
      }
    }
  },
  components: {
    ScheduleModal,
    SpeakerCard,
    Carousel,
    Slide
  },
  data() {
    return {
      selectedItem: {
        moreInfo: undefined
      },
      html: undefined
    }
  },
  methods: {
    selectItem(item){
      this.selectedItem = item
      this.html = '<b>' + this.selectedItem.name + '</b><br>' + this.selectedItem.moreInfo;
      if (this.html)
        this.$modal.show('speakers-modal');
    },
    navigation(direction){
      this.$refs.carousel.handleNavigation(direction)
    }
  }
}
</script>

<style lang="scss">
h1, h2 {
  @apply text-white;
}

.speakers {
  button {
    @apply bg-gray-900 w-8 h-8 md:w-16 md:h-16
    rounded-full text-white text-xl focus:outline-none
    hover:text-blue-900 hover:bg-gray-100 transition-colors duration-500;

    &:disabled {
      @apply bg-opacity-40 cursor-default hover:bg-gray-900 hover:text-white
    }

    &.prev {
      transform: rotate(180deg);
      @apply mr-2
    }
  }
}
</style>
